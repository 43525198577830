import React from "react";

const Footer = () => {
  return (
    <div className="footer">
    <div className="copyright">
      <p>
        Copyright © Designed &amp; Developed by{" "}
        <a href={process.env.REACT_APP_URL} target="_blank"  rel="noreferrer">
        {process.env.REACT_APP_BY}
        </a>{" "}
        2022
      </p>
    </div>
  </div>
  );
};

export default Footer;
